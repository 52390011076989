const data = [
	{
		name: 'cPanel',
		icon: 'Rust',
		license: true,
		pricings: [
			{
				id: 'cpanel-vps-1',
				link:
					'https://cloud.hostjane.com/hosting/store/aws/cpanel-vps-1',
			},
			{
				id: 'cpanel-vps-2',
				link:
					'https://cloud.hostjane.com/hosting/store/aws/cpanel-vps-2',
			},
			{
				id: 'cpanel-vps-3',
				link:
					'https://cloud.hostjane.com/hosting/store/aws/cpanel-vps-3',
			},
			{
				id: 'cpanel-vps-4',
				link:
					'https://cloud.hostjane.com/hosting/store/aws/cpanel-vps-4',
			},
			{
				id: 'cpanel-vps-5',
				link:
					'https://cloud.hostjane.com/hosting/store/aws/cpanel-vps-5',
			},
			{
				id: 'cpanel-vps-6',
				link:
					'https://cloud.hostjane.com/hosting/store/aws/cpanel-vps-6',
			},
		],
	},
	{
		name: 'DirectAdmin',
		icon: 'Sketch',
		pricings: [
			{
				id: 'directadmin-vps-1',
				link:
					'https://cloud.hostjane.com/hosting/store/aws/directadmin-vps-1',
			},
			{
				id: 'directadmin-vps-2',
				link:
					'https://cloud.hostjane.com/hosting/store/aws/directadmin-vps-2',
			},
			{
				id: 'directadmin-vps-3',
				link:
					'https://cloud.hostjane.com/hosting/store/aws/directadmin-vps-3',
			},
			{
				id: 'directadmin-vps-4',
				link:
					'https://cloud.hostjane.com/hosting/store/aws/directadmin-vps-4',
			},
			{
				id: 'directadmin-vps-5',
				link:
					'https://cloud.hostjane.com/hosting/store/aws/directadmin-vps-5',
			},
			{
				id: 'directadmin-vps-6',
				link:
					'https://cloud.hostjane.com/hosting/store/aws/directadmin-vps-6',
			},
		],
	},
	{
		name: 'Plesk',
		icon: 'Shopify',
		license: true,
		pricings: [
			{
				id: 'plesk-vps-1',
				link:
					'https://cloud.hostjane.com/hosting/store/aws/plesk-vps-1',
			},
			{
				id: 'plesk-vps-2',
				link:
					'https://cloud.hostjane.com/hosting/store/aws/plesk-vps-2',
			},
			{
				id: 'plesk-vps-3',
				link:
					'https://cloud.hostjane.com/hosting/store/aws/plesk-vps-3',
			},
			{
				id: 'plesk-vps-4',
				link:
					'https://cloud.hostjane.com/hosting/store/aws/plesk-vps-4',
			},
			{
				id: 'plesk-vps-5',
				link:
					'https://cloud.hostjane.com/hosting/store/aws/plesk-vps-5',
			},
			{
				id: 'plesk-vps-6',
				link:
					'https://cloud.hostjane.com/hosting/store/aws/plesk-vps-6',
			},
		],
	},
];

export default data;
