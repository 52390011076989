import virginia from './virginia/virginia';

const data = {
	title: 'Managed VPS Hosting',
	seoDescription:
		'AWS SSD VPS hosting on bare-metal compute managed for you by experts in high-performance VPS hosting.',
	helpText:
		'Buy a Virtual Private Server virtualized on AWS EC2 bare-metal managed by HostJane linux admins.',
	applications: [
		{
			name: 'Default Datacenter: AWS N. Virginia',
			items: virginia
		},
	],
	stepOneTitle: 'Select app and datacenter',
	stepTwoTitle: 'Choose a Plan - {{App}} VPS',
	stepThreeTitle: 'Confirm your agreement',
	checkboxText:
		'I have read the help guides. I understand my VPS will take up to 60 mins to finish installation before the server emails its IP and login details.',
	footerSummary: '{{Size}} — {{App}}',
	banner: '<span class="news-label">Help? ',
	alertIcon: 'cloud',
	alertTitle: 'Power your VPS with AWS EC2 Bare-Metal',
	alertMessage: 'Managed VPS on AWS EC2 Bare-Metal Compute. cPanel/WHM, DirectAdmin and Plesk with licensing you control. Bring your own license from those providers to use with your Amazon EC2 server.',
};

export default data;
