import React, { useReducer } from 'react';
import qs from 'querystring';

import Layout from '../Layout';
import Header from '../Header';
import StepTitle from '../StepTitle';
import { Tab, Tabs, TabList, TabPanel, TabPanels } from '../Tabs';
import Control from '../Control';
import { AppItems, AppItem } from '../AppItems';
import { PricingTables, PricingTable } from '../PricingTable';
import LaunchFooter from '../LaunchFooter';
import SEO from '../SEO';
import Alert from '../Alert';
import Checkbox from '../Checkbox';
import { findBy, formatString } from '../../utils/forms';
import UserGuideButton from '../UserGuideButton';

const initialState = {
	appType: 0,
	app: null,
	pricing: null,
	checked: false,
	hasChanged: false,
	hasInitialInfo: false,
};

function reducer(state, action) {
	if (action.type === 'setAppIndex') {
		const appType = action.payload;
		if (state.appType === appType) {
			return state;
		}
		return {
			...state,
			appType,
			app: null,
			pricing: null,
			checked: false,
			hasChanged: true,
		};
	}

	if (action.type === 'setApp') {
		const app = action.payload;
		if (state.app === app) {
			return state;
		}
		return {
			...state,
			app,
			pricing: null,
			checked: false,
			hasChanged: true,
		};
	}

	if (action.type === 'setPricing') {
		const pricing = action.payload;
		if (state.pricing === pricing) {
			return state;
		}

		return {
			...state,
			pricing,
			checked: false,
			hasChanged: true,
		};
	}

	if (action.type === 'setChecked') {
		const checked = action.payload;
		if (state.checked === checked) {
			return { ...state, hasChanged: true };
		}
		return {
			...state,
			checked,
			hasChanged: true,
		};
	}

	throw new Error(`Do not understand the type ${action.type}`);
}

export default function AppForm({
	data,
	pricings,
	userGuideModalData,
	...props
}) {
	const {
		applications,
		title,
		helpText,
		stepOneTitle,
		stepTwoTitle,
		stepThreeTitle,
		checkboxText,
		footerSummary,
	} = data;

	let urlAppType = 0;
	let urlApp = null;
	let hasInitialInfo = false;
	if (typeof window !== 'undefined' && window.location) {
		const urlInfo = qs.parse(window.location.search.replace('?', ''));
		if (urlInfo.appType) {
			const possibleAppType = Number.parseInt(urlInfo.appType, 10);
			if (!Number.isNaN(possibleAppType)) {
				urlAppType = possibleAppType;
				hasInitialInfo = true;
				if (urlInfo.app) {
					const possibleApp = Number.parseInt(urlInfo.app, 10);
					if (!Number.isNaN(possibleApp)) {
						urlApp = possibleApp;
					}
				}
			}
		}
	}

	const [state, dispatch] = useReducer(reducer, {
		...initialState,
		appType: urlAppType,
		app: urlApp,
		hasInitialInfo,
	});

	let selectedApp = null;
	let appName = '';
	if (state.app !== null) {
		selectedApp = applications[state.appType].items[state.app];
		appName = selectedApp.name;
	}

	let selectedPricing = null;
	let serverSize = '';
	let serverPrice = '';
	if (state.pricing !== null) {
		selectedPricing = selectedApp.pricings[state.pricing];
		const pricing = findBy(pricings, 'id', selectedPricing.id);
		serverSize = pricing.title;
		serverPrice = pricing.price;
	}

	const format = { appName, serverSize, serverPrice };

	return (
		<Layout {...props} banner={data.banner}>
			<SEO title={title} description={data.seoDescription} />
			<Header tooltip={helpText}>{title}</Header>
			{data.alertMessage !== null && data.alertMessage !== '' ? (
				<Alert
					icon={data.alertIcon}
					message={data.alertMessage}
					title={data.alertTitle}
				/>
			) : null}

			{/** Step 1 - App Type and Application */}
			{/** scrollOnMount={!state.hasChanged && state.hasInitialInfo} */}
			<Control>
				<StepTitle step="1" withTab>
					{formatString(stepOneTitle, format)}
				</StepTitle>
				<Tabs
					onChange={index => {
						dispatch({
							type: 'setAppIndex',
							payload: index,
						});
					}}
					index={state.appType}
				>
					<TabList>
						{applications.map(app => (
							<Tab key={app.name}>{app.name}</Tab>
						))}
					</TabList>
					<TabPanels>
						{applications.map(app => (
							<TabPanel key={app.name}>
								<AppItems>
									{app.items.map((aItem, aIndex) => {
										const aId = `${app.name}-${aIndex}`;

										return (
											<AppItem
												key={aId}
												active={state.app === aIndex}
												icon={aItem.icon}
												title={aItem.name}
												subtitle={aItem.subtitle}
												license={aItem.license || false}
												onClick={() =>
													dispatch({
														type: 'setApp',
														payload: aIndex,
													})
												}
											/>
										);
									})}
								</AppItems>
							</TabPanel>
						))}
					</TabPanels>
				</Tabs>
			</Control>

			{/** Step 2 - Pricing Table */}
			{selectedApp !== null ? (
				<Control scrollOnMount={state.hasChanged} key={state.app}>
					<StepTitle step="2">
						{formatString(stepTwoTitle, format)}
					</StepTitle>
					<PricingTables>
						{selectedApp.pricings.map((pTable, pIndex) => {
							const pId = `${state.appType}-${state.app}-${pIndex}`;
							const pricingTable = findBy(
								pricings,
								'id',
								pTable.id
							);
							return (
								<PricingTable
									key={pId}
									{...pricingTable}
									active={state.pricing === pIndex}
									onClick={() =>
										dispatch({
											type: 'setPricing',
											payload: pIndex,
										})
									}
								/>
							);
						})}
					</PricingTables>
				</Control>
			) : null}

			{/** Step 3 - Checkbox */}
			{selectedPricing !== null ? (
				<Control scrollOnMount={state.hasChanged}>
					<StepTitle step="3">{stepThreeTitle}</StepTitle>
					<Checkbox
						checked={state.checked}
						onChange={e => {
							dispatch({
								type: 'setChecked',
								payload: e.target.checked,
							});
						}}
					>
						<div
							dangerouslySetInnerHTML={{
								__html: checkboxText,
							}}
						/>
					</Checkbox>
					<UserGuideButton userGuideModalData={userGuideModalData} />
				</Control>
			) : null}

			{/** Step 4 - Footer */}
			{selectedPricing !== null && state.checked ? (
				<LaunchFooter
					scrollOnMount
					summary={serverPrice}
					label={formatString(footerSummary, format)}
					link={selectedPricing.link}
				/>
			) : null}
		</Layout>
	);
}
