import React from 'react';
import styled from 'styled-components';
import { UserGuideModal } from '../UserGuideModal';
import { useModal } from '../../utils/globalModal';

const Button = styled.div`
	@media screen and (max-width: 576px) {
		display: flex;
		justify-content: center;
	}
	a {
		cursor: pointer;
		display: inline-block;
		position: relative;
		text-align: center;
		width: auto;
		touch-action: manipulation;
		font-size: 16px;
		line-height: 20px;
		font-weight: 600;
		padding-top: 13px;
		padding-bottom: 13px;
		padding-left: 23px;
		padding-right: 23px;
		color: rgb(34, 34, 34);
		margin: 0px;
		margin-top: 20px;
		margin-left: 20px;
		@media screen and (max-width: 576px) {
			margin-left: 0;
		}
		text-decoration: none;
		border-radius: 8px;
		border-width: 1px;
		border-style: solid;
		outline: none;
		transition: box-shadow 0.2s ease 0s, -ms-transform 0.1s ease 0s,
			-webkit-transform 0.1s ease 0s, transform 0.1s ease 0s;
		border-color: rgb(34, 34, 34);
		background: rgb(255, 255, 255);
		&:hover,
		&:active {
			text-decoration: none;
			color: rgb(34, 34, 34);
			border-color: rgb(0, 0, 0);
			background: rgb(247, 247, 247);
		}

		&:active {
			transform: scale(0.96);
		}
	}
`;
const UserGuideButton = ({ userGuideModalData }) => {
	const [open, openModal, closeModal] = useModal();
	return (
		<>
			<Button>
				<a
					onClick={e => {
						e.preventDefault();
						openModal();
					}}
				>
					Read the help guides
				</a>
			</Button>
			<UserGuideModal
				userGuideModalData={userGuideModalData}
				open={open}
				closeModal={closeModal}
			/>
		</>
	);
};

export default UserGuideButton;
