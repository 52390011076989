const pricing = [
	{
		id: 'cpanel-vps-1',
		title: '2 vCPU / 2GB RAM',
		price: '$22.95 /mo',
		banner: 'AWS',
		features: ['2 Cores Intel® Xeon® CPU', '2GB Guaranteed RAM', '60GB NVMe SSD Storage', '3TB Premium Bandwidth', 'cPanel/WHM - 14-Day Trial License', 'Managed by admins for you with 24/7 support', 'Server automatically emails cPanel admin credentials after 50 min installation', 'Server Setup', 'White Label',  'Static Amazon IPv4', 'Up-to-date Versioning', 'Set-up on your custom domain', 'Free Auto-renewing SSL issued 45 minutes after you update A Record', 'Full Root Access', 'Full SSH Access', 'Secure AWS Data Center', '24/7 Support', '24/7 Monitoring'],
		rrp: 'FULLY-MANAGED',
	},
	{
		id: 'cpanel-vps-2',
		title: '2 vCPU / 4GB RAM',
		price: '$34.95 /mo',
		banner: 'AWS',
		features: ['2 Cores Intel® Xeon® CPU', '4GB Guaranteed RAM', '80GB NVMe SSD Storage', '4TB Premium Bandwidth', 'cPanel/WHM - 14-Day Trial License', 'Managed by admins for you with 24/7 support', 'Server automatically emails cPanel admin credentials after 50 min installation', 'Server Setup', 'White Label',  'Static Amazon IPv4', 'Up-to-date Versioning', 'Set-up on your custom domain', 'Free Auto-renewing SSL issued 45 minutes after you update A Record', 'Full Root Access', 'Full SSH Access', 'Secure AWS Data Center', '24/7 Support', '24/7 Monitoring'],
		rrp: 'FULLY-MANAGED',
	},
	{
		id: 'cpanel-vps-3',
		title: '2 vCPU / 8GB RAM',
		price: '$54.95 /mo',
		banner: 'AWS',
		features: ['2 Cores Intel® Xeon® CPU', '8GB Guaranteed RAM', '160GB NVMe SSD Storage', '5TB Premium Bandwidth', 'cPanel/WHM - 14-Day Trial License', 'Managed by admins for you with 24/7 support', 'Server automatically emails cPanel admin credentials after 50 min installation', 'Server Setup', 'White Label', 'Static Amazon IPv4', 'Up-to-date Versioning', 'Set-up on your custom domain', 'Free Auto-renewing SSL issued 45 minutes after you update A Record', 'Full Root Access', 'Full SSH Access', 'Secure AWS Data Center', '24/7 Support', '24/7 Monitoring'],
		rrp: 'FULLY-MANAGED',
	},
	{
		id: 'cpanel-vps-4',
		title: '4 vCPU / 16GB RAM',
		price: '$94.95 /mo',
		banner: 'AWS',
		features: ['4 Cores Intel® Xeon® CPU', '16GB Guaranteed RAM', '320GB NVMe SSD Storage', '6TB Premium Bandwidth', 'cPanel/WHM - 14-Day Trial License', 'Managed by admins for you with 24/7 support', 'Server automatically emails cPanel admin credentials after 50 min installation', 'Server Setup', 'White Label', 'Static Amazon IPv4', 'Up-to-date Versioning', 'Set-up on your custom domain', 'Free Auto-renewing SSL issued 45 minutes after you update A Record', 'Full Root Access', 'Full SSH Access', 'Secure AWS Data Center', '24/7 Support', '24/7 Monitoring'],
		rrp: 'FULLY-MANAGED',
	},
	{
		id: 'cpanel-vps-5',
		title: '8 vCPU / 32GB RAM',
		price: '$179.95 /mo',
		banner: 'AWS',
		features: ['8 Cores Intel® Xeon® CPU', '32GB Guaranteed RAM', '640GB NVMe SSD Storage', '7TB Premium Bandwidth', 'cPanel/WHM - 14-Day Trial License', 'Managed by admins for you with 24/7 support', 'Server automatically emails cPanel admin credentials after 50 min installation', 'Server Setup', 'White Label', 'Static Amazon IPv4', 'Up-to-date Versioning', 'Set-up on your custom domain', 'Free Auto-renewing SSL issued 45 minutes after you update A Record', 'Full Root Access', 'Full SSH Access', 'Secure AWS Data Center', '24/7 Support', '24/7 Monitoring'],
		rrp: 'FULLY-MANAGED',
	},
	{
		id: 'cpanel-vps-6',
		title: '16 vCPU / 64GB RAM',
		price: '$399.95 /mo',
		banner: 'AWS',
		features: ['16 Cores Intel® Xeon® CPU', '64GB Guaranteed RAM', '1,280GB NVMe SSD Storage', '8TB Premium Bandwidth', 'cPanel/WHM - 14-Day Trial License', 'Managed by admins for you with 24/7 support', 'Server automatically emails cPanel admin credentials after 50 min installation', 'Server Setup', 'White Label', 'Static Amazon IPv4', 'Up-to-date Versioning', 'Set-up on your custom domain', 'Free Auto-renewing SSL issued 45 minutes after you update A Record', 'Full Root Access', 'Full SSH Access', 'Secure AWS Data Center', '24/7 Support', '24/7 Monitoring'],
		rrp: 'FULLY-MANAGED',
	},
	{
		id: 'directadmin-vps-1',
		title: '2 vCPU / 2GB RAM',
		price: '$22.95 /mo',
		banner: 'AWS',
		features: ['2 Cores Intel® Xeon® CPU', '2GB Guaranteed RAM', '60GB NVMe SSD Storage', '3TB Premium Bandwidth', 'Enter Your Own DirectAdmin License during checkout', 'Managed by admins for you with 24/7 support', 'Server automatically emails DirectAdmin login credentials after 40 min installation', 'Server Setup', 'White Label',  'Static Amazon IPv4', 'Up-to-date Versioning', 'Set-up on your custom domain', 'Free Auto-renewing SSL issued 45 minutes after you update A Record', 'Full Root Access', 'Full SSH Access', 'Secure AWS Data Center', '24/7 Support', '24/7 Monitoring'],
		rrp: 'FULLY-MANAGED',
	},
	{
		id: 'directadmin-vps-2',
		title: '2 vCPU / 4GB RAM',
		price: '$34.95 /mo',
		banner: 'AWS',
		features: ['2 Cores Intel® Xeon® CPU', '4GB Guaranteed RAM', '80GB NVMe SSD Storage', '4TB Premium Bandwidth', 'Enter Your Own DirectAdmin License during checkout', 'Managed by admins for you with 24/7 support', 'Server automatically emails DirectAdmin login credentials after 40 min installation', 'Server Setup', 'White Label',  'Static Amazon IPv4', 'Up-to-date Versioning', 'Set-up on your custom domain', 'Free Auto-renewing SSL issued 45 minutes after you update A Record', 'Full Root Access', 'Full SSH Access', 'Secure AWS Data Center', '24/7 Support', '24/7 Monitoring'],
		rrp: 'FULLY-MANAGED',
	},
	{
		id: 'directadmin-vps-3',
		title: '2 vCPU / 8GB RAM',
		price: '$54.95 /mo',
		banner: 'AWS',
		features: ['2 Cores Intel® Xeon® CPU', '8GB Guaranteed RAM', '160GB NVMe SSD Storage', '5TB Premium Bandwidth', 'Enter Your Own DirectAdmin License during checkout', 'Managed by admins for you with 24/7 support', 'Server automatically emails DirectAdmin login credentials after 40 min installation', 'Server Setup', 'White Label', 'Static Amazon IPv4', 'Up-to-date Versioning', 'Set-up on your custom domain', 'Free Auto-renewing SSL issued 45 minutes after you update A Record', 'Full Root Access', 'Full SSH Access', 'Secure AWS Data Center', '24/7 Support', '24/7 Monitoring'],
		rrp: 'FULLY-MANAGED',
	},
	{
		id: 'directadmin-vps-4',
		title: '4 vCPU / 16GB RAM',
		price: '$94.95 /mo',
		banner: 'AWS',
		features: ['4 Cores Intel® Xeon® CPU', '16GB Guaranteed RAM', '320GB NVMe SSD Storage', '6TB Premium Bandwidth', 'Enter Your Own DirectAdmin License during checkout', 'Managed by admins for you with 24/7 support', 'Server automatically emails DirectAdmin login credentials after 40 min installation', 'Server Setup', 'White Label', 'Static Amazon IPv4', 'Up-to-date Versioning', 'Set-up on your custom domain', 'Free Auto-renewing SSL issued 45 minutes after you update A Record', 'Full Root Access', 'Full SSH Access', 'Secure AWS Data Center', '24/7 Support', '24/7 Monitoring'],
		rrp: 'FULLY-MANAGED',
	},
	{
		id: 'directadmin-vps-5',
		title: '8 vCPU / 32GB RAM',
		price: '$179.95 /mo',
		banner: 'AWS',
		features: ['8 Cores Intel® Xeon® CPU', '32GB Guaranteed RAM', '640GB NVMe SSD Storage', '7TB Premium Bandwidth', 'Enter Your Own DirectAdmin License during checkout', 'Managed by admins for you with 24/7 support', 'Server automatically emails DirectAdmin login credentials after 40 min installation', 'Server Setup', 'White Label', 'Static Amazon IPv4', 'Up-to-date Versioning', 'Set-up on your custom domain', 'Free Auto-renewing SSL issued 45 minutes after you update A Record', 'Full Root Access', 'Full SSH Access', 'Secure AWS Data Center', '24/7 Support', '24/7 Monitoring'],
		rrp: 'FULLY-MANAGED',
	},
	{
		id: 'directadmin-vps-6',
		title: '16 vCPU / 64GB RAM',
		price: '$399.95 /mo',
		banner: 'AWS',
		features: ['16 Cores Intel® Xeon® CPU', '64GB Guaranteed RAM', '1,280GB NVMe SSD Storage', '8TB Premium Bandwidth', 'Enter Your Own DirectAdmin License during checkout', 'Managed by admins for you with 24/7 support', 'Server automatically emails DirectAdmin login credentials after 40 min installation', 'Server Setup', 'White Label', 'Static Amazon IPv4', 'Up-to-date Versioning', 'Set-up on your custom domain', 'Free Auto-renewing SSL issued 45 minutes after you update A Record', 'Full Root Access', 'Full SSH Access', 'Secure AWS Data Center', '24/7 Support', '24/7 Monitoring'],
		rrp: 'FULLY-MANAGED',
	},
	{
		id: 'plesk-vps-1',
		title: '2 vCPU / 2GB RAM',
		price: '$22.95 /mo',
		banner: 'AWS',
		features: ['2 Cores Intel® Xeon® CPU', '2GB Guaranteed RAM', '60GB NVMe SSD Storage', '3TB Premium Bandwidth', 'Plesk - 14-Day Trial License', 'Managed by admins for you with 24/7 support', 'Server automatically emails Plesk one-time login URL after 50 min installation', 'Server Setup', 'White Label',  'Static Amazon IPv4', 'Up-to-date Versioning', 'Set-up on your custom domain', 'Install free SSL inside Plesk', 'Full Root Access', 'Full SSH Access', 'Secure AWS Data Center', '24/7 Support', '24/7 Monitoring'],
		rrp: 'FULLY-MANAGED',
	},
	{
		id: 'plesk-vps-2',
		title: '2 vCPU / 4GB RAM',
		price: '$34.95 /mo',
		banner: 'AWS',
		features: ['2 Cores Intel® Xeon® CPU', '4GB Guaranteed RAM', '80GB NVMe SSD Storage', '4TB Premium Bandwidth', 'Plesk - 14-Day Trial License', 'Managed by admins for you with 24/7 support', 'Server automatically emails Plesk one-time login URL after 50 min installation', 'Server Setup', 'White Label', 'Static Amazon IPv4', 'Up-to-date Versioning', 'Set-up on your custom domain', 'Install free SSL inside Plesk', 'Full Root Access', 'Full SSH Access', 'Secure AWS Data Center', '24/7 Support', '24/7 Monitoring'],
		rrp: 'FULLY-MANAGED',
	},
	{
		id: 'plesk-vps-3',
		title: '2 vCPU / 8GB RAM',
		price: '$54.95 /mo',
		banner: 'AWS',
		features: ['2 Cores Intel® Xeon® CPU', '8GB Guaranteed RAM', '160GB NVMe SSD Storage', '5TB Premium Bandwidth', 'Plesk - 14-Day Trial License', 'Managed by admins for you with 24/7 support', 'Server automatically emails Plesk one-time login URL after 50 min installation', 'Server Setup', 'White Label', 'Static Amazon IPv4', 'Up-to-date Versioning', 'Set-up on your custom domain', 'Install free SSL inside Plesk', 'Full Root Access', 'Full SSH Access', 'Secure AWS Data Center', '24/7 Support', '24/7 Monitoring'],
		rrp: 'FULLY-MANAGED',
	},
	{
		id: 'plesk-vps-4',
		title: '4 vCPU / 16GB RAM',
		price: '$94.95 /mo',
		banner: 'AWS',
		features: ['4 Cores Intel® Xeon® CPU', '16GB Guaranteed RAM', '320GB NVMe SSD Storage', '6TB Premium Bandwidth', 'Plesk - 14-Day Trial License', 'Managed by admins for you with 24/7 support', 'Server automatically emails Plesk one-time login URL after 50 min installation', 'Server Setup', 'White Label', 'Static Amazon IPv4', 'Up-to-date Versioning', 'Set-up on your custom domain', 'Install free SSL inside Plesk', 'Full Root Access', 'Full SSH Access', 'Secure AWS Data Center', '24/7 Support', '24/7 Monitoring'],
		rrp: 'FULLY-MANAGED',
	},
	{
		id: 'plesk-vps-5',
		title: '8 vCPU / 32GB RAM',
		price: '$179.95 /mo',
		banner: 'AWS',
		features: ['8 Cores Intel® Xeon® CPU', '32GB Guaranteed RAM', '640GB NVMe SSD Storage', '7TB Premium Bandwidth', 'Plesk - 14-Day Trial License', 'Managed by admins for you with 24/7 support', 'Server automatically emails Plesk one-time login URL after 50 min installation', 'Server Setup', 'White Label', 'Static Amazon IPv4', 'Up-to-date Versioning', 'Set-up on your custom domain', 'Install free SSL inside Plesk', 'Full Root Access', 'Full SSH Access', 'Secure AWS Data Center', '24/7 Support', '24/7 Monitoring'],
		rrp: 'FULLY-MANAGED',
	},
	{
		id: 'plesk-vps-6',
		title: '16 vCPU / 64GB RAM',
		price: '$399.95 /mo',
		banner: 'AWS',
		features: ['16 Cores Intel® Xeon® CPU', '64GB Guaranteed RAM', '1,280GB NVMe SSD Storage', '8TB Premium Bandwidth', 'Plesk - 14-Day Trial License', 'Managed by admins for you with 24/7 support', 'Server automatically emails Plesk one-time login URL after 50 min installation', 'Server Setup', 'White Label', 'Static Amazon IPv4', 'Up-to-date Versioning', 'Set-up on your custom domain', 'Install free SSL inside Plesk', 'Full Root Access', 'Full SSH Access', 'Secure AWS Data Center', '24/7 Support', '24/7 Monitoring'],
		rrp: 'FULLY-MANAGED',
	},
];

export default pricing;
