import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import {
	HIWItem,
	PaymentShield,
	Article,
	SmallBorderedParagraph,
	SmallFooterParagraph,
} from '../StyledContents';
import { Modal, ModalBody, ModalHeader } from '../Modal';

const GuideList = styled.ul`
	list-style-type: none;
	margin: 0;
	padding: 0;
	padding-left: 10px;
	li {
		&:first-of-type {
			border-top: 1px solid #edeef2;
		}
		padding: 10px 0px;
		text-decoration: none;
		border-bottom: 1px solid #edeef2;
		a {
			display: flex;
			align-items: center;
			font-size: 20px;
			font-weight: 500;
			text-decoration: none;
			border-bottom: none;
			svg {
				width: 40px;
				height: 40px;
				margin-right: 10px;
			}
		}
	}
`;

// TITLE OF MODAL

// REACT COMPONENT FOR MODAL CONTENT
export function ModalContent({ userGuideModalData }) {
	return (
		<Article>
			<SmallBorderedParagraph noBorder>
				{userGuideModalData.header}
			</SmallBorderedParagraph>
			<GuideList>
				{userGuideModalData.links.map(({ link, href, icon }, key) => {
					return (
						<li key={key}>
							<a href={href} target="__blank">
								{icon} {link}
							</a>
						</li>
					);
				})}
			</GuideList>
			<SmallFooterParagraph noBorder>
				{userGuideModalData.footer}
			</SmallFooterParagraph>
		</Article>
	);
}

// DONT EDIT THIS
export function UserGuideModal({ userGuideModalData, open, closeModal }) {
	return (
		<Modal
			isOpen={open}
			closeModal={closeModal}
			contentLabel={userGuideModalData.title}
		>
			<ModalHeader
				title={userGuideModalData.title}
				closeModal={closeModal}
			/>
			<ModalBody>
				<ModalContent userGuideModalData={userGuideModalData} />
			</ModalBody>
		</Modal>
	);
}
