import React from 'react';
import { ReactComponent as CPanelVPSIcon } from '../../src/svgs/wizard/Apps/CentOS.svg';
import { ReactComponent as PleskIcon } from '../../src/svgs/wizard/Apps/brushes.svg';
import { ReactComponent as DirectAdminIcon } from '../../src/svgs/wizard/Apps/Gitlab.svg';
import { ReactComponent as NextCloudIcon } from '../../src/svgs/wizard/Apps/NextCloud.svg';
import { ReactComponent as OwnCloudIcon } from '../../src/svgs/wizard/Apps/OwnCloud.svg';
import { ReactComponent as PrestaShopIcon } from '../../src/svgs/wizard/Apps/PrestaShop.svg';
import { ReactComponent as WebminIcon } from '../../src/svgs/wizard/Apps/Webmin.svg';

const userGuideModalData = {
	title: 'Help guides for VPS',
	header:
		'Contact your domain registrar to confirm how to update your domain name\'s A record before ordering.',
	footer: '24/7/365 support available for all plans.',
	links: [
		{
			link: 'How to point your domain to hosting',
			href: 'https://help.hostjane.com/point-your-domain',
			icon: <PrestaShopIcon />,
		},
		{
			link: 'cPanel/WHM VPS Tutorial',
			href: 'https://help.hostjane.com/vps-hosting-tutorials/cpanel-whm/',
			icon: <CPanelVPSIcon />,
		},
		{
			link: 'Plesk VPS Tutorial',
			href: 'https://help.hostjane.com/vps-hosting-tutorials/plesk/',
			icon: <PleskIcon />,
		},
		{
			link: 'DirectAdmin VPS Tutorial',
			href: 'https://help.hostjane.com/vps-hosting-tutorials/directadmin/',
			icon: <DirectAdminIcon />,
		},
		{
			link: 'VPS Service Information',
			href: 'https://help.hostjane.com/vps-hosting/',
			icon: <OwnCloudIcon />,
		},
		{
			link: 'Hosting Terms of Service',
			href: 'https://www.hostjane.com/legal/hosting-terms',
			icon: <NextCloudIcon />,
		},
		{
			link: 'Contact Support',
			href: 'https://www.hostjane.com/marketplace/contact',
			icon: <WebminIcon />,
		},
	],
};

export default userGuideModalData;
