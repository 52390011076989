import React from 'react';
// the only component we need
import AppForm from '../../components/AppForm';

// import data
import pricings from '../../../form-data/vps/pricings';
import data from '../../../form-data/vps';
import userGuideModalData from '../../../site-data/user-guide-modal';

export default function VPSHostingWizard(props) {
	return (
		<AppForm
			userGuideModalData={userGuideModalData}
			pricings={pricings}
			data={data}
			{...props}
		/>
	);
}

